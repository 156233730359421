import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { googleLoginApi, tokenVerifyApi, getUserApi } from "../api/authApi";
import { getUserByIdApi } from "../api/userApi";
import { getDocumentByIdUserApi } from "../api/documentApi";
import { PATHS } from "../routes/paths";
import toaster from "../components/Toast/Toast";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [document, setDocument] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("uid");
  const documentId = searchParams.get("did");
  const navigate = useNavigate();

  const logout = useCallback(() => {
    localStorage.removeItem("accessToken");
    setIsAuthenticated(false);
    setUser(null);
    setDocument(null);
    navigate(PATHS.PUBLIC.LOGIN, { replace: true });
  }, [navigate]);

  // Setup global auth error handler
  useEffect(() => {
    window.handleAuthError = () => {
      setIsAuthenticated(false);
      setUser(null);
      navigate(PATHS.PUBLIC.LOGIN, { replace: true });
    };

    return () => {
      delete window.handleAuthError;
    };
  }, [navigate]);

  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await getUserApi();
      if (!response.success) {
        throw new Error(response.message || "Failed to fetch user details");
      }
      setUser(response.user);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setIsAuthenticated(false);
      setUser(null);
      if (error.response?.status === 401) {
        logout();
      }
    }
  }, [logout]);

  const verifyToken = useCallback(
    async (token) => {
      if (!token) {
        setIsAuthenticated(false);
        setLoading(false);
        return;
      }

      try {
        const response = await tokenVerifyApi(token);
        if (!response || !response.success) {
          throw new Error("Token verification failed");
        }
        await fetchUserDetails();
      } catch (error) {
        console.error("Token verification failed:", error);
        setIsAuthenticated(false);
        setUser(null);
        localStorage.removeItem("accessToken");
        logout();
      } finally {
        setLoading(false);
      }
    },
    [fetchUserDetails, logout]
  );

  // Check token validity on mount
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      verifyToken(accessToken);
    } else {
      setLoading(false);
    }
  }, [verifyToken]);

  const googleLogin = useCallback(
    async (googleToken) => {
      setLoading(true);
      try {
        const response = await googleLoginApi(googleToken);
        if (!response.success) {
          throw new Error(response.error || "Login failed");
        }
        localStorage.setItem("accessToken", response.token);
        setIsAuthenticated(true);
        setUser(response.user);
        navigate(PATHS.PROTECTED.DASHBOARD, { replace: true });
      } catch (error) {
        console.error("Login error:", error);
        setIsAuthenticated(false);
        setUser(null);
        toaster.error(error.message || "Failed to process login");
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [navigate]
  );

  const fetchDocument = useCallback(async () => {
    setLoading(true);
    try {
      if (!userId || !documentId) {
        throw new Error("Missing required parameters");
      }

      const response = await getDocumentByIdUserApi(documentId, userId);
      if (!response?.success) {
        throw new Error(response?.message || "Failed to fetch document");
      }
      
      // Fetch user details for the document owner
      try {
        const userResponse = await getUserByIdApi(response.document.user_id);
        if (userResponse?.success && userResponse.user) {
          // Combine document data with user name
          setDocument({
            ...response.document,
            userName: userResponse.user.name // Add user name to document object
          });
        } else {
          // If user fetch fails, still show document but with default name
          setDocument({
            ...response.document,
            userName: 'Unknown User'
          });
        }
      } catch (userError) {
        console.error("Error fetching user details:", userError);
        // Still show document even if user details fetch fails
        setDocument({
            ...response.document,
            userName: 'Unknown User'
        });
      }
      
    } catch (error) {
      console.error("Error fetching document:", error);
      if (error?.message === "This document is not accessible via shortlink") {
        toaster.error("This document link has been disabled by the owner");
      } else if (error.response?.status === 401) {
        setIsAuthenticated(false);
        logout();
      } else {
        toaster.error("Failed to load document");
      }
    } finally {
      setLoading(false);
    }
  }, [userId, documentId, logout]);
  

  useEffect(() => {
    if (userId && documentId) {
      fetchDocument();
    }
  }, [userId, documentId, fetchDocument]);

  const contextValue = useMemo(
    () => ({
      user,
      document,
      isAuthenticated,
      loading,
      googleLogin,
      logout,
    }),
    [user, document, isAuthenticated, loading, logout, googleLogin]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);