import axiosInstance from "./axiosInstance";

export const generateQuestionsApi = async (data) => {
  try {
    const response = await axiosInstance.post("/generate-questions", data);
    return response.data;
  } catch (error) {
    // Transform error to match our error structure
    const errorResponse = {
      status: "error",
      code: error.response?.data?.code || "UNKNOWN_ERROR",
      detail: error.response?.data?.detail || "An unexpected error occurred"
    };
    throw errorResponse;
  }
};
