import React from "react";
import micIcon from "../../assets/icons/micIcon.svg";
import { STARTTALKING_TEXT } from "../../constants/constant";
import styles from "./StartTalking.module.css";

const StartTalking = () => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <img src={micIcon} alt="micIcon" />
      </div>
      <h1 className={styles.title}> {STARTTALKING_TEXT.title} </h1>
      <p className={styles.subtitle}>
       {STARTTALKING_TEXT.helper}
      </p>
    </div>
  );
};

export default StartTalking;
