export const ERROR_MESSAGES = {
    INVALID_INPUT: "The provided input is invalid. Please check your input and try again.",
    GENERATION_FAILED: "Failed to generate questions. Please try again.",
    GENERATION_ERROR: "An error occurred while generating questions. Please try again.",
    UNKNOWN_ERROR: "An unexpected error occurred. Please try again.",
    // Add more error messages as needed
  };
  export const UPLOAD_ERROR_MESSAGES = {
    AUTH_REQUIRED: "Please login to upload documents",
    INVALID_INPUT: "Please provide all required information",
    FILE_REQUIRED: "Please select a PDF file",
    TITLE_REQUIRED: "Please enter a document title",
    UPLOAD_ERROR: "Failed to upload document",
    PROCESSING_ERROR: "Document uploaded but processing failed",
    NETWORK_ERROR: "Network error occurred. Please check your connection",
  };
  
  export const getErrorMessage = (errorCode) => {
    return ERROR_MESSAGES[errorCode] || ERROR_MESSAGES.UNKNOWN_ERROR;
  };
  