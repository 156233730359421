import React from "react";
import styles from "./ChatBox.module.css";
import CustomButton from "../../components/CustomButton";
import IconButton from "../IconButton";
import CommonBox from "../../components/CommonBox";
import StartTalking from "../StartTalking";
import TranscriptionDisplay from "../TranscriptionDisplay";
import ReviewFeedback from "../ReviewFeedback";
import { CHAT_TEXT } from "../../constants/constant";
import starIcon from "../../assets/icons/starIcon.svg";
import { FaArrowRightLong } from "react-icons/fa6";
import ServiceSelector from "../ServiceSelector";

import { LuMicOff, LuMic } from "react-icons/lu";

import Waveform from "../Wave/index";

const ChatBox = ({
  handleStart,
  handleNext,
  step,
  questions,
  isConnected,
  isRecording,
  setIsRecording,
  intensity,
  transcriptionLoading,
  pdfFile,
  currentQuestionIndex,
  setSTTService,
  selectedService,
  transcription,
  setTranscription,
  reviewTranscription,
  pageNumber,
  handleReviewConfirm,
  setStep,
  setCurrentQuestionIndex,
  summary,
  setSummary,
}) => {
  const handlePlayPause = () => {
    setIsRecording((prevstate) => !prevstate);
  };
  const handleTranscriptionDelete = (step, pageNumber) => {
    setTranscription((prev) => {
      // Find the index of the item to delete
      const index = prev.findIndex(
        (item) => item.step === step && item.pageNumber === pageNumber
      );

      if (index === -1) return prev;

      // Create a new array without the deleted item
      const newArray = [...prev];
      newArray.splice(index, 1);
      return newArray;
    });
  };
  return (
    <CommonBox width="30.78%">
      <div
        className={!isConnected ? styles.chatBox : styles.chatBoxMain}
        style={{ justifyContent: !pdfFile && "center" }}
      >
        {!isConnected ? (
          <>
            {pdfFile && (
              <div>
                <h2 className={styles.heading}>{CHAT_TEXT.heading}</h2>
                <hr className={styles.lineBreak} />
                <p className={styles.helperText}>{CHAT_TEXT.howWork}</p>
                <div className={styles.stepsContainer}>
                  {CHAT_TEXT.workStep.map((item) => (
                    <div key={item.step} className={styles.stepRow}>
                      <div className={styles.stepBox}>{item.step}</div>
                      <p className={styles.stepText}>{item.text}</p>
                    </div>
                  ))}
                </div>
                <hr className={styles.lineBreak} />
              </div>
            )}
            <div
              className={`${styles.startButtonBox} ${
                pdfFile ? styles.startButtonAtBottom : ""
              }`}
            >
              <CustomButton
                handleButtonClick={handleStart}
                text={CHAT_TEXT.feedbackButtonText}
                glow={true}
                backgroundColor="#39F2AE"
                textColor="white"
                height="44px"
                radius="25px" // Rounded corners
                icon={starIcon} // Icon at the start
                iconPosition="start"
                gap="12px" // Default gap between icon and text
              />
            </div>
            <ServiceSelector
              selectedService={selectedService}
              onServiceChange={(e) => setSTTService(e)}
            />
          </>
        ) : (
          <div className={styles.chatContentBox}>
            <div className={styles.chatContent}>
              {step === 1 || step === 2 ? (
                <>
                  <h2 className={styles.heading}>{CHAT_TEXT.thoughtText}</h2>
                  <p className={styles.helperText}>{CHAT_TEXT.startInfo}</p>
                  <hr className={styles.lineBreak} />
                  {/* <div className={styles.wave}>
                    {isRecording && <Waveform intensity={intensity} />}
                  </div>
                  <div className={styles.listenBox}>
                    <div
                      className={styles.circle}
                      style={{
                        backgroundColor: !isRecording ? "#A1A1A1" : "#39F2AE",
                      }}
                    />
                    <p
                      className={styles.listenText}
                      style={{ color: !isRecording ? "#A1A1A1" : "#39F2AE" }}
                    >
                      {isRecording ? "Listening" : CHAT_TEXT.micOffText}
                    </p>
                  </div> */}
                  {transcription.length === 0 ? (
                    <StartTalking />
                  ) : (
                    <TranscriptionDisplay
                      transcription={transcription}
                      pageNumber={pageNumber}
                      onDelete={handleTranscriptionDelete}
                      step={step}
                      questions={questions}
                      currentQuestionIndex={currentQuestionIndex}
                    />
                  )}
                </>
              ) : step === 3 ? (
                <>
                  <h2 className={styles.heading}>
                    {CHAT_TEXT.feedbackReviewTitle}
                  </h2>
                  <p className={styles.helperText}>
                    {CHAT_TEXT.feedbackReviewText}
                  </p>
                  {/* <hr className={styles.lineBreak} /> */}
                  {/* <div className={styles.helperBox}>
                    <QuestionNavigator
                      questions={questions}
                      currentQuestionIndex={currentQuestionIndex}
                    />
                    {isRecording && <Waveform intensity={intensity} />}
                  </div> */}
                  <ReviewFeedback
                    setStep={setStep}
                    summary={summary}
                    setSummary={setSummary}
                  />
                </>
              ) : (
                step === 5 && (
                  <>
                    <h2 className={styles.heading}>{CHAT_TEXT.thoughtText}</h2>
                    <p className={styles.helperText}>{CHAT_TEXT.timeText}</p>
                    <hr className={styles.lineBreak} />
                    <TranscriptionDisplay
                      transcription={reviewTranscription}
                      pageNumber={pageNumber}
                      step={step}
                    />
                  </>
                )
              )}
            </div>
            <div className={styles.chatFooter}>
              {step === 3 ? (
                <>
                  <div className={styles.footerReviewBox}>
                    <CustomButton
                      // handleButtonClick={handleReviewConfirm}
                      text="Cancel"
                      backgroundColor="transparent"
                      loading={transcriptionLoading}
                      textColor="#5D6673"
                      paddingX="35px"
                      height="48px"
                      radius="25px" // Rounded corners
                    />
                    <CustomButton
                      handleButtonClick={handleReviewConfirm}
                      text="Confirm"
                      backgroundColor="#39F2AE"
                      glow={true}
                      loading={transcriptionLoading}
                      textColor="black"
                      paddingX="35px"
                      height="48px"
                      radius="25px" // Rounded corners
                    />
                  </div>
                </>
              ) : (
                <>
                  <hr className={styles.lineBreak} />
                  <p className={styles.helperText}>{CHAT_TEXT.muteText}</p>
                  <div className={styles.footerBox}>
                    <div className={styles.listenBox}>
                      <IconButton
                        handleButtonClick={handlePlayPause}
                        icon={!isRecording ? LuMicOff : LuMic}
                        backgroundColor={
                          !isRecording ? "rgb(93 102 115 / 25%)" : "#39F2AE40"
                        } // Red background
                        iconColor="#39F2AE" // Black icon
                        isLoading={transcriptionLoading}
                      />
                      {isRecording && (
                        <div className={styles.listenBox}>
                          <div className={styles.circle} />
                          <p className={styles.listenText}>Listening...</p>
                        </div>
                      )}
                    </div>

                    <CustomButton
                      handleButtonClick={handleNext}
                      text={
                        step === 1
                          ? CHAT_TEXT.nextText
                          : currentQuestionIndex < questions.length - 1
                          ? CHAT_TEXT.nextQuestion
                          : CHAT_TEXT.finishText
                      }
                      backgroundColor="#39F2AE"
                      glow={true}
                      loading={transcriptionLoading}
                      textColor="black"
                      paddingX="35px"
                      height="46px"
                      radius="25px" // Rounded corners
                      Icon={FaArrowRightLong} // Icon at the start
                      iconPosition="end"
                      gap="12px" // Default gap between icon and text
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </CommonBox>
  );
};
export default ChatBox;
