import React, { createContext, useContext } from 'react';
import useSessionPersistence from '../hooks/useSessionPersistence';

const SessionContext = createContext(null);

export const SessionProvider = ({ children }) => {
  const sessionManager = useSessionPersistence();

  return (
    <SessionContext.Provider value={sessionManager}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};