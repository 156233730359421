// Summary.jsx
import styles from "./Summary.module.css";
import CommonBox from "../CommonBox";
import CustomButton from "../CustomButton";
import { SUMMARY_TEXT } from "../../constants/constant";
import starIcon from "../../assets/icons/starIcon.svg";
import SummaryDocument from "../SummaryDocument";

const Summary = ({ handleStartOver, summary }) => {
  const getSeverityColor = (severity) => {
    switch (severity) {
      case 'high':
        return '#FF4646';
      case 'medium':
        return '#FFA500';
      case 'low':
        return '#39F2AE';
      default:
        return '#FFFFFF';
    }
  };

  const getTypeIcon = (type) => {
    switch (type) {
      case 'visual':
        return '👁️';
      case 'content':
        return '📝';
      case 'technical':
        return '⚙️';
      case 'accessibility':
        return '♿';
      case 'structure':
        return '🏗️';
      default:
        return '📌';
    }
  };

  // Check if summary has valid data
  const hasSummaryData = summary && 
    (summary.feedback_summary?.length > 0 || 
     summary.misc?.key_takeaways || 
     summary.misc?.additional_notes);

  return (
    <CommonBox width="100%">
      <div className={styles.summaryMain}>
        <div className="w-full">
          <div className={styles.top}>
            <h2 className={styles.heading}>{SUMMARY_TEXT.heading}</h2>
            <div className={styles.buttonContainer}>
              {/* Show SummaryDocument if there's any valid data */}
              {hasSummaryData && <SummaryDocument data={summary} />}

              <CustomButton
                handleButtonClick={handleStartOver}
                text={SUMMARY_TEXT.startText}
                backgroundColor="#39F2AE"
                glow={true}
                textColor="white"
                height="48px"
                radius="25px"
                icon={starIcon}
                iconPosition="start"
                gap="12px"
              />
            </div>
          </div>
          <div className={styles.bottom}>
            {summary?.feedback_summary?.length > 0 && (
              <div className={styles.box}>
                <div className="container-95">
                  {summary.feedback_summary.map((issue, i) => (
                    <div key={i} className={styles.issueContainer}>
                      <div className={styles.issueHeader}>
                        <div className={styles.pageNumber}>
                          Page {issue.pageNumber}
                        </div>
                        <div 
                          className={styles.severity}
                          style={{ color: getSeverityColor(issue.severity) }}
                        >
                          {issue.severity}
                        </div>
                        <div className={styles.type}>
                          {getTypeIcon(issue.type)} {issue.type}
                        </div>
                      </div>
                      <h3 className={styles.secondaryHeading}>
                        {issue.issueTitle}
                      </h3>
                      <p className={styles.secondaryText}>
                        {issue.issueExplanation}
                      </p>
                      {issue.context && (
                        <div className={styles.context}>
                          <p className={styles.contextText}>{issue.context}</p>
                        </div>
                      )}
                      {i !== summary.feedback_summary.length - 1 && (
                        <hr className={styles.separator} />
                      )}
                    </div>
                  ))}
                  
                  {summary.misc && (
                    <div className={styles.miscContainer}>
                      <hr className={styles.separator} />
                      {summary.misc.key_takeaways && (
                        <div className={styles.miscSection}>
                          <h3 className={styles.secondaryHeading}>Key Takeaways</h3>
                          <p className={styles.secondaryText}>
                            {summary.misc.key_takeaways}
                          </p>
                        </div>
                      )}
                      {summary.misc.additional_notes && (
                        <div className={styles.miscSection}>
                          <h3 className={styles.secondaryHeading}>Additional Notes</h3>
                          <p className={styles.secondaryText}>
                            {summary.misc.additional_notes}
                          </p>
                        </div>
                      )}
                      {summary.misc.context_insights && (
                        <div className={styles.miscSection}>
                          <h3 className={styles.secondaryHeading}>Context Insights</h3>
                          <p className={styles.secondaryText}>
                            {summary.misc.context_insights}
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </CommonBox>
  );
};

export default Summary;