import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import IconButton from "../IconButton";
import { FaFilePdf } from "react-icons/fa6";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: "Helvetica",
    fontSize: 12,
    lineHeight: 1.6,
  },
  titlePage: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  titleText: {
    fontSize: 30,
    fontWeight: "bold",
    marginBottom: 10,
  },
  subtitleText: {
    fontSize: 18,
    marginBottom: 30,
    color: "#666",
  },
  section: {
    marginBottom: 20,
  },
  heading: {
    fontSize: 18,
    marginBottom: 5,
    fontWeight: "bold",
  },
  issueHeader: {
    flexDirection: 'row',
    marginBottom: 5,
    gap: 10,
  },
  pageNumber: {
    fontSize: 10,
    backgroundColor: "#e8e8e8",
    padding: "2 6",
    borderRadius: 3,
  },
  severity: {
    fontSize: 10,
    textTransform: 'uppercase',
  },
  type: {
    fontSize: 10,
    color: "#666",
  },
  paragraph: {
    fontSize: 12,
    color: "#333",
    marginBottom: 10,
  },
  context: {
    fontSize: 11,
    color: "#666",
    backgroundColor: "#f5f5f5",
    padding: 10,
    marginTop: 5,
    borderRadius: 3,
  },
  miscSection: {
    marginTop: 20,
    paddingTop: 20,
    borderTop: 1,
    borderColor: "#eee",
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    color: "#999",
  },
});

// Title Page Component
const TitlePage = () => (
  <Page size="A4" style={styles.page}>
    <View style={styles.titlePage}>
      <Text style={styles.titleText}>Client Report</Text>
      <Text style={styles.subtitleText}>
        Detailed Insights and Recommendations
      </Text>
    </View>
    <Text style={styles.footer}>Generated by hi-client.com</Text>
  </Page>
);

// Get severity color
const getSeverityColor = (severity) => {
  switch (severity) {
    case 'high':
      return '#FF4646';
    case 'medium':
      return '#FFA500';
    case 'low':
      return '#39F2AE';
    default:
      return '#666';
  }
};

// Content Pages Component
const ContentPages = ({ data }) => (
  <Page size="A4" style={styles.page}>
    {data?.feedback_summary?.length > 0 ? (
      <>
        {data.feedback_summary.map((issue, index) => (
          <View key={index} style={styles.section}>
            <View style={styles.issueHeader}>
              <Text style={styles.pageNumber}>Page {issue.pageNumber}</Text>
              <Text style={[styles.severity, { color: getSeverityColor(issue.severity) }]}>
                {issue.severity}
              </Text>
              <Text style={styles.type}>{issue.type}</Text>
            </View>
            <Text style={styles.heading}>{issue.issueTitle}</Text>
            <Text style={styles.paragraph}>{issue.issueExplanation}</Text>
            {issue.context && (
              <Text style={styles.context}>{issue.context}</Text>
            )}
          </View>
        ))}

        {/* Misc Section */}
        {data.misc && (
          <View style={styles.miscSection}>
            {data.misc.key_takeaways && (
              <View style={styles.section}>
                <Text style={styles.heading}>Key Takeaways</Text>
                <Text style={styles.paragraph}>{data.misc.key_takeaways}</Text>
              </View>
            )}
            {data.misc.additional_notes && (
              <View style={styles.section}>
                <Text style={styles.heading}>Additional Notes</Text>
                <Text style={styles.paragraph}>{data.misc.additional_notes}</Text>
              </View>
            )}
            {data.misc.context_insights && (
              <View style={styles.section}>
                <Text style={styles.heading}>Context Insights</Text>
                <Text style={styles.paragraph}>{data.misc.context_insights}</Text>
              </View>
            )}
          </View>
        )}
      </>
    ) : (
      <Text>No content available</Text>
    )}
    <Text style={styles.footer}>Generated by hi-client.com - Page 2</Text>
  </Page>
);

// Main Document Component
const MyDocument = ({ data }) => (
  <Document>
    <TitlePage />
    <ContentPages data={data} />
  </Document>
);

// Main App Component
const SummaryDocument = ({ data }) => {
  // Check if summary has valid data
  const hasSummaryData = data && 
    (data.feedback_summary?.length > 0 || 
     data.misc?.key_takeaways || 
     data.misc?.additional_notes);

  return (
    <div>
      {hasSummaryData ? (
        <PDFDownloadLink
          document={<MyDocument data={data} />}
          fileName="Client_Report.pdf"
        >
          {({ loading }) =>
            loading ? (
              "Loading..."
            ) : (
              <IconButton
                icon={FaFilePdf}
                backgroundColor="#39F2AE"
                iconColor="#fff"
                glow={true}
              />
            )
          }
        </PDFDownloadLink>
      ) : (
        <p>Loading data...</p>
      )}
    </div>
  );
};

export default SummaryDocument;