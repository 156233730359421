import React from "react";
import CustomButton from "../CustomButton";
import { FiDownload } from "react-icons/fi";
import styles from "./NavigationBar.module.css";

const NavigationBar = ({
  currentPage = 1,
  totalPages = 1,
  onPrevPage,
  onNextPage,
  onAction,
  actionLabel = "Action",
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <CustomButton
          handleButtonClick={onPrevPage}
          text="Previous"
          glow={false}
          backgroundColor="#39F2AE"
          textColor="#404040"
          disabled={currentPage <= 1}
          height="30px"
          radius="25px" // Rounded corners
          width="110px"
        />

        <span className={styles.pageText}>
          Page {currentPage} of {totalPages}
        </span>
        <CustomButton
          handleButtonClick={onNextPage}
          text="Next"
          glow={false}
          backgroundColor="#39F2AE"
          textColor="#404040"
          height="30px"
          radius="25px" // Rounded corners
          width="110px"
          disabled={currentPage >= totalPages}
        />
      </div>

      <CustomButton
        handleButtonClick={onAction}
        text={actionLabel}
        glow={false}
        backgroundColor="#39F2AE"
        textColor="#404040"
        height="30px"
        radius="25px" // Rounded corners
        Icon={FiDownload} // Icon at the start
        iconPosition="start"
      />
    </div>
  );
};

export default NavigationBar;
