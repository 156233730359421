import { useState, useEffect } from 'react';

const STORAGE_KEY = 'feedback_session';
const SESSION_DISCARDED_KEY = 'feedback_session_discarded';

const useSessionPersistence = () => {
  // Initial session state
  const initialState = {
    step: 1,
    transcription: [],
    questions: [],
    currentQuestionIndex: 0,
    summary: [],
    pageNumber: 1,
    isConnected: false,
    sessionStarted: false
  };

  // Load session data from storage
  const loadSessionData = () => {
    const savedSession = sessionStorage.getItem(STORAGE_KEY);
    return savedSession ? JSON.parse(savedSession) : null;
  };

  const [sessionData, setSessionData] = useState(null);
  const [showResumePrompt, setShowResumePrompt] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  // Initial session check
  useEffect(() => {
    const savedData = loadSessionData();
    const wasDiscarded = sessionStorage.getItem(SESSION_DISCARDED_KEY) === 'true';
    
    // session was started, and it wasn't explicitly discarded
    if (savedData && !isInitialized && savedData.sessionStarted && !wasDiscarded) {
      setShowResumePrompt(true);
    }
    setIsInitialized(true);
  }, []);

  // Save to sessionStorage whenever state changes
  useEffect(() => {
    if (sessionData) {
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(sessionData));
    }
  }, [sessionData]);

  const updateSession = (updates) => {
    setSessionData(prev => ({
      ...prev,
      ...updates
    }));
  };

  const clearSession = () => {
    sessionStorage.removeItem(STORAGE_KEY);
    sessionStorage.removeItem(SESSION_DISCARDED_KEY);
    setSessionData(null);
    setShowResumePrompt(false);
  };

  const resumeSession = () => {
    const savedData = loadSessionData();
    if (savedData) {
      setSessionData(savedData);
      // Clear the discarded flag when resuming
      sessionStorage.removeItem(SESSION_DISCARDED_KEY);
    }
    setShowResumePrompt(false);
  };

  const discardSession = () => {
    clearSession();
    // Set a flag indicating this session was explicitly discarded
    sessionStorage.setItem(SESSION_DISCARDED_KEY, 'true');
    setSessionData({ ...initialState });
  };

  const startNewSession = () => {
    // Clear the discarded flag when starting new session
    sessionStorage.removeItem(SESSION_DISCARDED_KEY);
    setSessionData({
      ...initialState,
      sessionStarted: true,
      isConnected: true
    });
  };

  const hasExistingSession = () => {
    const savedData = sessionStorage.getItem(STORAGE_KEY);
    const wasDiscarded = sessionStorage.getItem(SESSION_DISCARDED_KEY) === 'true';
    if (!savedData || wasDiscarded) return false;
    const parsedData = JSON.parse(savedData);
    return parsedData.sessionStarted;
  };

  return {
    sessionData,
    updateSession,
    clearSession,
    hasExistingSession,
    showResumePrompt,
    resumeSession,
    discardSession,
    startNewSession
  };
};

export default useSessionPersistence;