import { isValidJSON } from "./helper";
import toaster from "../components/Toast/Toast";

const PING_INTERVAL = 30000;
const RECONNECT_DELAY = 3000;
const MAX_RECONNECT_ATTEMPTS = 3;

const createWebSocketConnection = (
  path,
  onOpen,
  onMessage,
  onError,
  onClose
) => {
  let reconnectAttempts = 0;
  let pingInterval;
  let reconnectTimeout;

  const connect = () => {
    const websocket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}${path}`
    );

    const startPingInterval = () => {
      if (pingInterval) clearInterval(pingInterval);
      pingInterval = setInterval(() => {
        if (websocket.readyState === WebSocket.OPEN) {
          websocket.send(JSON.stringify({ type: "ping" }));
        }
      }, PING_INTERVAL);
    };

    const stopPingInterval = () => {
      if (pingInterval) {
        clearInterval(pingInterval);
        pingInterval = null;
      }
    };

    const clearReconnectTimeout = () => {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }
    };

    websocket.onopen = () => {
      reconnectAttempts = 0;
      startPingInterval();
      onOpen();
    };

    websocket.onmessage = onMessage;

    websocket.onerror = (error) => {
      stopPingInterval();
      onError(error);
    };

    websocket.onclose = (event) => {
      stopPingInterval();
      clearReconnectTimeout();

      if (
        !event.wasClean &&
        event.code === 1006 &&
        reconnectAttempts < MAX_RECONNECT_ATTEMPTS
      ) {
        reconnectAttempts++;
        toaster.info(
          `Connection lost. Reconnecting... Attempt ${reconnectAttempts}/${MAX_RECONNECT_ATTEMPTS}`
        );

        reconnectTimeout = setTimeout(() => {
          connect();
        }, RECONNECT_DELAY);
      } else {
        onClose(event);
      }
    };

    return websocket;
  };

  return connect();
};

// Connect to Whisper WebSocket.
export const connectWhisperWebSocket = (
  onOpen,
  onMessage,
  onError,
  onClose
) => {
  return createWebSocketConnection(
    "/whisper",
    onOpen,
    onMessage,
    onError,
    onClose
  );
};

// Connect to Azure WebSocket
export const connectAzureWebSocket = (onOpen, onMessage, onError, onClose) => {
  return createWebSocketConnection(
    "/azure",
    onOpen,
    onMessage,
    onError,
    onClose
  );
};

// Handle Whisper Messages
export const handleWhisperMessage = (
  event,
  setTranscription,
  setReviewTranscription,
  setEndLoader,
  currentStep,
  pageNumber,
  setTranscriptionLoading,
  currentRequestId
) => {
  if (!isValidJSON(event.data)) {
    setEndLoader(false);
    toaster.error("Received non-JSON message");
    console.log("Received non-JSON message:", event.data);
    return;
  }

  const output = JSON.parse(event.data);
  console.log("whisper", output);

  if (output && output.error) {
    toaster.error(`Error: ${output.error}`);
    console.error("Received error:", output.error);
    setTranscriptionLoading(false);
    return;
  }

  if (output && output.status === "success") {
    const { requestId } = output;

    if (requestId === currentRequestId) {
      setTranscriptionLoading(false);
    }

    switch (output.type) {
      case "transcription":
        setTranscription((prev) => [
          ...prev,
          { text: output.text, step: currentStep, pageNumber: pageNumber },
        ]);
        if (currentStep === 5) {
          setReviewTranscription((prev) => [
            ...prev,
            { text: output.text, step: currentStep, pageNumber: pageNumber },
          ]);
        }
        break;
      default:
        break;
    }
  }
};

// Handle Azure Messages
export const handleAzureMessage = (
  event,
  setTranscription,
  setReviewTranscription,
  setEndLoader,
  currentStep,
  pageNumber,
  setTranscriptionLoading,
  currentRequestId
) => {
  if (!isValidJSON(event.data)) {
    setEndLoader(false);
    toaster.error("Received non-JSON message");
    console.log("Received non-JSON message:", event.data);
    return;
  }

  const output = JSON.parse(event.data);
  console.log("azure message:", output);

  if (output && output.error) {
    toaster.error(`Error: ${output.error}`);
    console.error("Received error:", output.error);
    setTranscriptionLoading(false);
    return;
  }

  if (output && output.status === "success") {
    const { requestId } = output;

    if (requestId === currentRequestId) {
      setTranscriptionLoading(false);
    }

    switch (output.type) {
      case "transcription":
        setTranscription((prev) => [
          ...prev,
          { text: output.text, step: currentStep, pageNumber: pageNumber },
        ]);
        if (currentStep === 5) {
          setReviewTranscription((prev) => [
            ...prev,
            { text: output.text, step: currentStep, pageNumber: pageNumber },
          ]);
        }
        break;
      default:
        break;
    }
  }
};
