import React, { useEffect, useRef, useState } from "react";
import { MdDelete } from "react-icons/md";
import styles from "./TranscriptionDisplay.module.css";

const TranscriptionDisplay = ({
  transcription,
  pageNumber,
  onDelete,
  step,
  questions,
  currentQuestionIndex,
}) => {
  const containerRef = useRef(null);
  const [displayItems, setDisplayItems] = useState([]);
  const lastPageNumber = useRef(pageNumber);
  const processedTranscriptions = useRef(new Set());
  const lastQuestionIndex = useRef(currentQuestionIndex);

  // Handle initial step 2 transition
  useEffect(() => {
    if (
      step === 2 &&
      !displayItems.some((item) => item.type === "stepTransition2")
    ) {
      setDisplayItems((prev) => [
        ...prev,
        {
          type: "stepTransition2",
          id: `step-2-transition-${Date.now()}`,
          text: "Thanks for the feedback, Allow me a few question to make the feedback clearer.",
        },
        {
          type: "question",
          id: `question-initial-${Date.now()}`,
          text: questions[currentQuestionIndex].question_text,
        },
      ]);
      lastQuestionIndex.current = currentQuestionIndex;
    }
  }, [step, questions, currentQuestionIndex]);

  // Handle question changes
  useEffect(() => {
    if (
      step === 2 &&
      questions &&
      questions[currentQuestionIndex] &&
      currentQuestionIndex !== lastQuestionIndex.current
    ) {
      setDisplayItems((prev) => [
        ...prev,
        {
          type: "question",
          id: `question-${currentQuestionIndex}-${Date.now()}`,
          text: questions[currentQuestionIndex].question_text,
        },
      ]);
      lastQuestionIndex.current = currentQuestionIndex;
    }
    if (
      step === 5 &&
      !displayItems.some((item) => item.type === "stepTransition5")
    ) {
      setDisplayItems((prev) => [
        ...prev,
        {
          type: "stepTransition5",
          id: `step-5-transition-${Date.now()}`,
          text: "Can you tell us your additional feedback?",
        },
      ]);
    }
  }, [currentQuestionIndex, questions, step]);

  // Handle transcriptions
  useEffect(() => {
    if (transcription.length > 0) {
      const lastTranscription = transcription[transcription.length - 1];
      const transcriptionKey = `${lastTranscription.step}-${lastTranscription.pageNumber}-${lastTranscription.text}`;

      if (!processedTranscriptions.current.has(transcriptionKey)) {
        processedTranscriptions.current.add(transcriptionKey);

        setDisplayItems((prev) => [
          ...prev,
          {
            type: "transcription",
            text: lastTranscription.text,
            step: lastTranscription.step,
            pageNumber: lastTranscription.pageNumber,
            id: `${lastTranscription.step}-${
              lastTranscription.pageNumber
            }-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
          },
        ]);
      }
    }
  }, [transcription]);

  // Handle page changes
  useEffect(() => {
    if (pageNumber !== lastPageNumber.current) {
      setDisplayItems((prev) => [
        ...prev,
        {
          type: "pageChange",
          text: `Moved to page ${pageNumber}`,
          pageNumber,
          id: `page-change-${Date.now()}-${Math.random()
            .toString(36)
            .substr(2, 9)}`,
        },
      ]);
      lastPageNumber.current = pageNumber;
    }
  }, [pageNumber]);

  // Auto-scroll to bottom whenever content updates
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [displayItems]);

  const handleDelete = (item) => {
    if (item.type === "transcription") {
      const transcriptionKey = `${item.step}-${item.pageNumber}-${item.text}`;
      processedTranscriptions.current.delete(transcriptionKey);
      onDelete && onDelete(item.step, item.pageNumber);
      setDisplayItems((prev) => prev.filter((i) => i.id !== item.id));
    }
  };

  const renderItem = (item) => {
    switch (item.type) {
      case "transcription":
        return (
          <div key={item.id} className={styles.transcriptItem}>
            <span>{item.text}</span>
            {onDelete && (
              <div
                className={styles.deleteIcon}
                onClick={() => handleDelete(item)}
                title="Delete transcription"
              >
                <MdDelete size={18} color="#FF4646" />
              </div>
            )}
          </div>
        );
      case "pageChange":
        return (
          <div key={item.id} className={styles.pageChangeItem}>
            <span>{item.text}</span>
          </div>
        );
      case "stepTransition2":
      case "stepTransition5":
      case "question":
        return (
          <div key={item.id} className={styles.feedbackMessage}>
            {item.text}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div ref={containerRef} className={styles.transcriptContainer}>
      {displayItems.map((item) => renderItem(item))}
    </div>
  );
};

export default TranscriptionDisplay;
