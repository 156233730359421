// FeedbackCard.jsx
import React from "react";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import styles from "./ReviewCard.module.css";

const ReviewCard = ({
  pageNumber,
  title,
  text,
  showActions = true,
  onEdit,
  onDelete,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        {showActions && (
          <div className={styles.actions}>
            <FiEdit2 className={styles.icon}  onClick={onEdit} />
            <FiTrash2 className={styles.icon} color="red" onClick={onDelete} />
          </div>
        )}

        <div className={styles.header}>
          {pageNumber && (
            <span className={styles.pageNumber}>Page {pageNumber}</span>
          )}
          {title && <h3 className={styles.title}>{title}</h3>}
        </div>

        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
};

export default ReviewCard;
