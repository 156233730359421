import axiosInstance from "./axiosInstance";

export const getCurrentUserApi = async () => {
  try {
    const response = await axiosInstance.get("/users/me");
    if (!response?.data) {
      throw new Error("Invalid response format");
    }
    return response.data;
  } catch (error) {
    throw (
      error.response?.data || {
        success: false,
        message: "Failed to fetch current user details",
      }
    );
  }
};

export const getUserByIdApi = async (userId) => {
  try {
    const response = await axiosInstance.get(`/users/${userId}`);
    if (!response?.data) {
      throw new Error("Invalid response format");
    }
    return response.data;
  } catch (error) {
    throw (
      error.response?.data || {
        success: false,
        message: "Failed to fetch user details",
      }
    );
  }
};