import React, { useState, useEffect, useRef } from "react";
import CustomButton from "../CustomButton";
import FeedbackPopup from "../FeedbackPopup";
import ReviewCard from "../ReviewCard";
import { CHAT_TEXT } from "../../constants/constant";
import plusIcon from "../../assets/icons/plusIcon.svg";
import typeIcon from "../../assets/icons/typeIcon.svg";
import micIcon from "../../assets/icons/micIcon2.svg";
import styles from "./ReviewFeedback.module.css";

const ReviewFeedback = ({ setStep, summary, setSummary }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [mode, setMode] = useState("add");
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleButtonClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOptionClick = (option) => {
    if (option === "type") {
      handleAddNew();
    } else if (option === "record") {
      setStep(5);
    }
    setShowDropdown(false);
  };

  const handleEdit = (data, originalItem) => {
    setEditingData({
      title: data.title,
      description: data.text || data.description,
      page: data.pageNumber || data.page,
      originalItem: originalItem,
    });
    setMode("edit");
    setIsPopupOpen(true);
  };

  const handleAddNew = () => {
    setEditingData(null);
    setMode("add");
    setIsPopupOpen(true);
  };

  const handleDelete = (itemToDelete) => {
    setSummary((prevSummary) => {
      // Create a copy of the previous summary
      const newSummary = { ...prevSummary };

      // Find the index of the item to delete by matching all relevant properties
      const itemIndex = newSummary.feedback_summary.findIndex(
        (item) =>
          item.pageNumber === itemToDelete.pageNumber &&
          item.issueTitle === itemToDelete.issueTitle &&
          item.issueExplanation === itemToDelete.issueExplanation
      );

      // If item found, remove it from the array
      if (itemIndex !== -1) {
        newSummary.feedback_summary = [
          ...newSummary.feedback_summary.slice(0, itemIndex),
          ...newSummary.feedback_summary.slice(itemIndex + 1),
        ];
      }

      return newSummary;
    });
  };

  const handleConfirm = (formData) => {
    setSummary((prevSummary) => {
      const newSummary = { ...prevSummary };

      if (mode === "edit" && editingData.originalItem) {
        const itemIndex = newSummary.feedback_summary.findIndex(
          (item) =>
            item.pageNumber === editingData.originalItem.pageNumber &&
            item.issueTitle === editingData.originalItem.issueTitle &&
            item.issueExplanation === editingData.originalItem.issueExplanation
        );

        if (itemIndex !== -1) {
          newSummary.feedback_summary[itemIndex] = {
            ...newSummary.feedback_summary[itemIndex],
            pageNumber: formData.pageNumber,
            issueTitle: formData.title,
            issueExplanation: formData.text,
          };
        }
      } else {
        const newFeedback = {
          pageNumber: formData.pageNumber,
          issueTitle: formData.title,
          issueExplanation: formData.text,
          severity: "low",
          type: "content",
        };
        newSummary.feedback_summary = [
          ...newSummary.feedback_summary,
          newFeedback,
        ];
      }

      return newSummary;
    });
  };

  return (
    <div className={styles.container}>
      <FeedbackPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onConfirm={handleConfirm}
        initialData={editingData}
        mode={mode}
      />
      <div className={styles.buttonContainer}>
        <div className={styles.dropdownWrapper}>
          <div ref={buttonRef}>
            <CustomButton
              handleButtonClick={handleButtonClick}
              text="Add Feedback"
              glow={true}
              backgroundColor="#39F2AE"
              textColor="black"
              height="44px"
              radius="25px"
              iconImage={plusIcon}
              iconPosition="start"
              gap="12px"
            />
          </div>
          {showDropdown && (
            <div className={styles.dropdown} ref={dropdownRef}>
              <button
                className={styles.dropdownOption}
                onClick={() => handleOptionClick("type")}
              >
                <img src={typeIcon} alt="Type" />
                <span>Type New Feedback</span>
              </button>
              <button
                className={styles.dropdownOption}
                onClick={() => handleOptionClick("record")}
              >
                <img src={micIcon} alt="Record" />
                <span>Record Additional Feedback</span>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={styles.box}>
        {summary?.feedback_summary?.length > 0 &&
          summary.feedback_summary.map((el, i) => (
            <ReviewCard
              pageNumber={el.pageNumber}
              key={i}
              title={el.issueTitle}
              text={el.issueExplanation}
              showActions={true}
              onEdit={() =>
                handleEdit(
                  {
                    title: el.issueTitle,
                    description: el.issueExplanation,
                    page: el.pageNumber,
                  },
                  el
                )
              }
              onDelete={() => handleDelete(el)}
            />
          ))}
        {summary?.general_feedback && (
          <>
            <p className={styles.title}>
              {CHAT_TEXT.feedbackReviewHelperTitle}
            </p>
            <ReviewCard text={summary.general_feedback} showActions={false} />
          </>
        )}
      </div>
    </div>
  );
};

export default ReviewFeedback;
