// requestQueueManager.js
class RequestQueueManager {
    constructor(maxConcurrentRequests = 1) {
      this.queue = [];
      this.activeRequests = 0;
      this.maxConcurrentRequests = maxConcurrentRequests;
    }
  
    async enqueueRequest(requestFunc) {
      return new Promise((resolve, reject) => {
        const queueItem = {
          requestFunc,
          resolve,
          reject
        };
        
        this.queue.push(queueItem);
        this.processQueue();
      });
    }
  
    async processQueue() {
      if (this.activeRequests >= this.maxConcurrentRequests || this.queue.length === 0) {
        return;
      }
  
      this.activeRequests++;
      const { requestFunc, resolve, reject } = this.queue.shift();
  
      try {
        const result = await requestFunc();
        resolve(result);
      } catch (error) {
        reject(error);
      } finally {
        this.activeRequests--;
        this.processQueue();
      }
    }
  
    clearQueue() {
      this.queue = [];
      this.activeRequests = 0;
    }
  }
  
  export const queueManager = new RequestQueueManager(1);