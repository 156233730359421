import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { IoMdPerson } from "react-icons/io";
import { FiCalendar, FiLink } from "react-icons/fi";

import { getReviewByIdApi } from "../../../api/reviewApi";
import { isObjectEmpty, formatDate } from "../../../helper/helper";
import { useBreadcrumb } from "../../../context/BreadcrumbContext";
import { SUMMARY_COLORS } from "../../../constants/constant";

import styles from "./FeedbackGuest.module.css";

import toaster from "../../../components/Toast/Toast";
import OverlayLoader from "../../../components/OverlayLoader";
import GuestReportDownload from "../../../components/GuestReportDownload";

// Helper function to get severity color
const getSeverityColor = (severity) => {
  if (!severity) return '#1890FF'; // Default color
  switch (severity.toLowerCase()) {
    case 'high':
      return '#FF4D4F';
    case 'medium':
      return '#FAAD14';
    case 'low':
      return '#52C41A';
    default:
      return '#1890FF';
  }
};

// Helper function to get type icon or label
const getTypeLabel = (type) => {
  if (!type) return 'General Issue';
  switch (type.toLowerCase()) {
    case 'content':
      return 'Content Issue';
    case 'technical':
      return 'Technical Issue';
    case 'visual':
      return 'Visual Issue';
    default:
      return 'General Issue';
  }
};

// Helper function to check if item uses new structure
const isNewStructure = (item) => {
  return item.hasOwnProperty('issueTitle') && item.hasOwnProperty('issueExplanation');
};

// Helper function to get the title from either structure
const getTitle = (item) => {
  if (isNewStructure(item)) {
    return item.issueTitle;
  }
  return item.title || 'Untitled';
};

// Helper function to get the description/text from either structure
const getDescription = (item) => {
  if (isNewStructure(item)) {
    return item.issueExplanation;
  }
  return item.summary_text || item.text || 'No description available';
};

const FeedbackGuest = () => {
  const { filename, guestId } = useParams();
  const { setPathDisplay } = useBreadcrumb();
  const [review, setReview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [isNewFormat, setIsNewFormat] = useState(false);

  useEffect(() => {
    if (review) {
      setPathDisplay({
        [`/feedback/${filename}`]: review.document_title,
        [`/feedback/${filename}/${guestId}`]: `Guest ${guestId} Feedback`,
      });
      
      // Determine the format by checking the first summary item
      if (review.summary?.length > 0) {
        setIsNewFormat(isNewStructure(review.summary[0]));
      }
    }
  }, [review, filename, guestId, setPathDisplay]);

  useEffect(() => {
    fetchReview();
  }, []);

  const fetchReview = async () => {
    setLoadingText("Getting Reviews...");
    setIsLoading(true);
    try {
      const response = await getReviewByIdApi(guestId);
      if (!response?.success) {
        throw response;
      }
      setReview(response.review);
    } catch (error) {
      console.error("Error fetching review:", error);
      toaster.error(
        error.message || "Failed to fetch review. Please try again later."
      );
    } finally {
      setLoadingText("");
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <OverlayLoader text={loadingText} />;
  }

  if (!review || !isObjectEmpty(review)) {
    return <p className={styles.noData}>No review found</p>;
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{review.document_title}</h2>
      <p className={styles.name}>Guest {review.id} Feedback</p>
      
      <div className={styles.detailBox}>
        <div className={styles.item}>
          <div className={styles.label}>
            <IoMdPerson size={20} />
            <span>Reviewer</span>
          </div>
          <div className={styles.value}>Guest #{review.id}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            <FiCalendar size={20} />
            <span>Date</span>
          </div>
          <div className={styles.value}>{formatDate(review.created_at)}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            <FiLink size={20} />
            <span>Report</span>
          </div>
          {review && <GuestReportDownload document={review} />}
        </div>
      </div>

      <div className={styles.boxContainer}>
        <div className={styles.commonBox}>
          <p className={styles.heading2}>Key Takeaways</p>
          {review.misc?.key_takeaways ? (
            <p className={styles.heading2Text}>{review.misc.key_takeaways}</p>
          ) : (
            <p className={styles.heading2Text}>Not Found</p>
          )}
        </div>

        <div className={styles.commonBox}>
          <p className={styles.heading2}>Detailed Feedback</p>
          {review.summary?.length > 0 ? (
            review.summary.map((item, index) => (
              <div key={index} className={styles.summarySection}>
                <div className={styles.summaryHeader}>
                  <p
                    className={styles.heading3}
                    style={{ color: getSeverityColor(item.severity) }}
                  >
                    {getTitle(item)}
                  </p>
                  {isNewFormat && (
                    <div className={styles.tags}>
                      <span className={styles.typeTag}>
                        {getTypeLabel(item.type)}
                      </span>
                      <span className={styles.pageTag}>
                        Page {item.pageNumber || 'N/A'}
                      </span>
                    </div>
                  )}
                </div>
                <p className={styles.heading2Text}>{getDescription(item)}</p>
                {isNewFormat && item.context && (
                  <p className={styles.contextText}>
                    Context: {item.context}
                  </p>
                )}
              </div>
            ))
          ) : (
            <p className={styles.heading2Text}>Not Found</p>
          )}
        </div>

        <div className={styles.commonBox}>
          <p className={styles.heading2}>Additional Notes</p>
          {review.misc?.additional_notes ? (
            <p className={styles.heading2Text}>
              {review.misc.additional_notes}
            </p>
          ) : (
            <p className={styles.heading2Text}>Not Found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedbackGuest;