import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { FaFilePdf } from "react-icons/fa6";
import IconButton from "../IconButton";
import { formatDate } from "../../helper/helper";

const styles = StyleSheet.create({
  // ... existing styles remain the same
  page: {
    padding: 30,
    fontFamily: "Helvetica",
    fontSize: 12,
    lineHeight: 1.6,
  },
  titlePage: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  titleText: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
  },
  documentTitle: {
    fontSize: 18,
    marginBottom: 20,
    color: "#666",
  },
  dateText: {
    fontSize: 14,
    color: "#666",
    marginBottom: 30,
  },
  section: {
    marginBottom: 25,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 8,
    color: "#333",
  },
  heading: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#444",
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    color: "#333",
  },
  metadata: {
    flexDirection: 'row',
    marginBottom: 5,
    flexWrap: 'wrap',
  },
  tag: {
    fontSize: 10,
    marginRight: 10,
    color: "#666",
  },
  context: {
    fontSize: 11,
    marginTop: 5,
    marginBottom: 10,
    fontStyle: 'italic',
    color: "#666",
  },
  questionSection: {
    marginTop: 15,
    marginBottom: 8,
  },
  question: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
  },
  answer: {
    fontSize: 12,
    marginBottom: 15,
    paddingLeft: 15,
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,
    textAlign: "center",
    fontSize: 10,
    color: "#999",
  },
  linkValue: {
    color: "#39f2ae",
    fontSize: "18px",
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
    margin: 0,
    marginTop: "15px",
  },
});

// Helper function to check if item uses new structure
const isNewStructure = (item) => {
  return item.hasOwnProperty('issueTitle') && item.hasOwnProperty('issueExplanation');
};

// Helper function to get the title from either structure
const getTitle = (item) => {
  if (isNewStructure(item)) {
    return item.issueTitle;
  }
  return item.title || 'Untitled';
};

// Helper function to get the description from either structure
const getDescription = (item) => {
  if (isNewStructure(item)) {
    return item.issueExplanation;
  }
  return item.summary_text || item.text || 'No description available';
};

// Helper function to determine if the document uses new structure
const isNewFormat = (summary) => {
  if (!summary || !Array.isArray(summary) || summary.length === 0) return false;
  return isNewStructure(summary[0]);
};

const TitlePage = ({ documentTitle, createdAt }) => (
  <Page size="A4" style={styles.page}>
    <View style={styles.titlePage}>
      <Text style={styles.titleText}>Guest Feedback Report</Text>
      <Text style={styles.documentTitle}>{documentTitle}</Text>
      <Text style={styles.dateText}>{formatDate(createdAt)}</Text>
    </View>
    <Text style={styles.footer}>Generated by hi-client.com</Text>
  </Page>
);

const SummaryPage = ({ summary, misc }) => {
  const useNewFormat = isNewFormat(summary);

  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Key Findings</Text>
        {summary.map((item, index) => (
          <View key={index} style={styles.section}>
            <Text style={styles.heading}>{getTitle(item)}</Text>
            {useNewFormat && (
              <View style={styles.metadata}>
                <Text style={styles.tag}>Type: {item.type || 'N/A'}</Text>
                <Text style={styles.tag}>Severity: {item.severity || 'N/A'}</Text>
                <Text style={styles.tag}>Page: {item.pageNumber || 'N/A'}</Text>
              </View>
            )}
            <Text style={styles.text}>{getDescription(item)}</Text>
            {useNewFormat && item.context && (
              <Text style={styles.context}>Context: {item.context}</Text>
            )}
          </View>
        ))}
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Key Takeaways</Text>
        <Text style={styles.text}>{misc?.key_takeaways || 'Not available'}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Additional Notes</Text>
        <Text style={styles.text}>{misc?.additional_notes || 'Not available'}</Text>
      </View>
      <Text style={styles.footer}>Generated by hi-client.com</Text>
    </Page>
  );
};

const QuestionsPage = ({ questions, answerText, transcription }) => (
  <Page size="A4" style={styles.page}>
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>Detailed Feedback</Text>
      <Text style={styles.text}>{transcription || 'No detailed feedback available'}</Text>
    </View>

    <View style={styles.section}>
      <Text style={styles.sectionTitle}>Follow-up Questions & Answers</Text>
      {questions?.length > 0 ? (
        questions.map((q, index) => (
          <View key={index} style={styles.questionSection}>
            <Text style={styles.question}>{`Q${index + 1}: ${q.question_text}`}</Text>
          </View>
        ))
      ) : (
        <Text style={styles.text}>No questions available</Text>
      )}
      <Text style={styles.answer}>{answerText || 'No answer provided'}</Text>
    </View>
    <Text style={styles.footer}>Generated by hi-client.com</Text>
  </Page>
);

const GuestReport = ({ document }) => (
  <Document>
    <TitlePage
      documentTitle={document.document_title}
      createdAt={document.created_at}
    />
    <SummaryPage summary={document.summary || []} misc={document.misc || {}} />
    <QuestionsPage
      questions={document.questions || []}
      answerText={document.answer_text}
      transcription={document.transcription}
    />
  </Document>
);

const GuestReportDownload = ({ document }) => (
  <div>
    {document ? (
      <PDFDownloadLink
        document={<GuestReport document={document} />}
        fileName={`${document.document_title}_Feedback_Report.pdf`}
      >
        {({ loading }) =>
          loading ? (
            "Loading..."
          ) : (
            <div style={styles.linkValue}>Download report</div>
          )
        }
      </PDFDownloadLink>
    ) : (
      <p>Loading data...</p>
    )}
  </div>
);

export default GuestReportDownload;