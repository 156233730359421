import toaster from "../components/Toast/Toast";
import { getErrorMessage } from "../utils/errorMessages";
export const callApiWithLoader = async (
  apiFunc,
  payload,
  successHandler,
  loadingMessage,
  setLoadingText,
  setEndLoader,
  setMicPermission
) => {
  try {
    setLoadingText(loadingMessage);
    setEndLoader(true);
    setMicPermission(false);
    const response = await apiFunc(payload);

    response && setMicPermission(true);
    successHandler(response);
  } catch (error) {
    console.error("API Error:", error);
    const errorMessage = getErrorMessage(error.code);
    toaster.error(
      errorMessage ||
        "An error occurred while processing your request. Please try again."
    );
    setMicPermission(true);
  } finally {
    setMicPermission(true);
    setEndLoader(false);
    setLoadingText(null);
  }
};
