import React from 'react';
import styles from './ResumeSessionModal.module.css';

const ResumeSessionModal = ({ 
  isOpen, 
  onResume, 
  onDiscard,
  sessionData 
}) => {
  if (!isOpen) return null;

  const getSessionSummary = () => {
    const step = sessionData?.step;
    if (step === 1) {
      return 'initial feedback recording';
    } else if (step === 2) {
      return `question ${sessionData.currentQuestionIndex + 1} of ${sessionData.questions.length}`;
    }
    return 'summary generation';
  };

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.overlay} />
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <h2 className={styles.title}>Resume Previous Session?</h2>
          <div className={styles.description}>
            <p>
              We found a saved session from your previous feedback. You were at{' '}
              <span className={styles.highlight}>{getSessionSummary()}</span>.
            </p>
            <p>Would you like to resume where you left off or start a new session?</p>
          </div>
        </div>
        
        <div className={styles.footer}>
          <button 
            onClick={onDiscard}
            className={styles.cancelButton}
          >
            Start New Session
          </button>
          <button 
            onClick={onResume}
            className={styles.confirmButton}
          >
            Resume Session
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResumeSessionModal;