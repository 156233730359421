import React from 'react';
import styles from './ServiceSelector.module.css';

const ServiceSelector = ({ selectedService, onServiceChange }) => {
  return (
    <div className={styles.selectorContainer}>
      <label className={styles.label}>
        Speech Service:
        <select 
          className={styles.select}
          value={selectedService}
          onChange={(e) => onServiceChange(e.target.value)}
        >
          <option value="azure">Azure Speech</option>
          <option value="whisper">OpenAI Whisper</option>
        </select>
      </label>
    </div>
  );
};

export default ServiceSelector;