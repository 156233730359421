import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  publicRoutes,
  protectedRoutes,
  isDashboardPath,
} from "./routes/config";
import Navbar from "./components/Navbar";
import { DashboardNavbar } from "./components/Navbar/DashNavbar";
// import Footer from "./components/Footer";
import RouteGuard from "./components/RouteGuard";
import DashboardLayout from "./layout/DashboardLayout";
import { AuthProvider } from "./context/AuthContext";
import { BreadcrumbProvider } from "./context/BreadcrumbContext";
import { SessionProvider } from "./context/SessionContext";
import { ToastContainer } from "react-toastify";
import { pdfjs } from "react-pdf";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

function App() {
  // Set the worker path
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url
  ).toString();
  const location = useLocation();

  const renderNavbar = () => {
    if (isDashboardPath(location.pathname)) {
      return <DashboardNavbar />;
    }
    return <Navbar />;
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthProvider>
        <SessionProvider>
          <BreadcrumbProvider>
            <ToastContainer />
            <div className="App">
              {renderNavbar()}
              <div>
                <Routes>
                  {/* Public Routes */}
                  {publicRoutes.map(({ path, element: Element }) => (
                    <Route key={path} path={path} element={<Element />} />
                  ))}

                  {/* Protected Routes */}
                  {protectedRoutes.map(({ path, element: Element }) => (
                    <Route
                      key={path}
                      path={path}
                      element={
                        <RouteGuard
                          element={Element}
                          layout={DashboardLayout}
                        />
                      }
                    />
                  ))}
                </Routes>
              </div>
              {/* <Footer /> */}
            </div>
          </BreadcrumbProvider>
        </SessionProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
