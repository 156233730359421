// FeedbackPopup.jsx
import React, { useState, useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import { BsTextParagraph } from 'react-icons/bs';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { RiPagesFill } from 'react-icons/ri';
import toaster from '../Toast/Toast';
import styles from './FeedbackPopup.module.css';

const DEFAULT_FORM_DATA = {
  title: '',
  description: '',
  page: ''
};

const DEFAULT_ERRORS = {
  title: '',
  description: '',
  page: ''
};

const FeedbackPopup = ({
  isOpen,
  onClose,
  onConfirm,
  initialData,
  mode = 'add'
}) => {
  const [formData, setFormData] = useState(() => {
    if (!initialData) return DEFAULT_FORM_DATA;
    
    return {
      title: initialData.title || '',
      description: initialData.description || initialData.text || '',
      page: initialData.page || initialData.pageNumber || '',
    };
  });

  const [errors, setErrors] = useState(DEFAULT_ERRORS);
  const [touched, setTouched] = useState(DEFAULT_ERRORS);

  useEffect(() => {
    if (mode === 'add') {
      setFormData(DEFAULT_FORM_DATA);
    } else if (initialData) {
      setFormData({
        title: initialData.title || '',
        description: initialData.description || initialData.text || '',
        page: initialData.page || initialData.pageNumber || '',
      });
    }
    setErrors(DEFAULT_ERRORS);
    setTouched(DEFAULT_ERRORS);
  }, [mode, initialData]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const validateField = (name, value) => {
    if (!value.trim()) {
      return `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
    
    const error = validateField(name, value);
    setErrors(prev => ({
      ...prev,
      [name]: error
    }));
  };

  const validateForm = () => {
    const newErrors = {
      title: validateField('title', formData.title),
      description: validateField('description', formData.description),
      page: validateField('page', formData.page)
    };

    setErrors(newErrors);
    setTouched({
      title: true,
      description: true,
      page: true
    });

    return !Object.values(newErrors).some(error => error);
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      toaster.error('Please fill in all required fields');
      return;
    }

    const submissionData = {
      title: formData.title.trim(),
      text: formData.description.trim(),
      pageNumber: formData.page.trim(),
    };
    onConfirm(submissionData);
    toaster.success(mode === 'add' ? 'Feedback added successfully' : 'Feedback updated successfully');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      <div className={styles.backdrop} onClick={onClose} />
      <div className={styles.container}>
        <div className={styles.popup}>
          <button className={styles.closeButton} onClick={onClose}>
            <IoClose size={24} color="#A1A1A1" />
          </button>
          
          <h2 className={styles.title}>
            {mode === 'add' ? 'Add Feedback' : 'Edit Feedback'}
          </h2>

          <div className={styles.inputGroup}>
            <div className={styles.label}>
              <HiOutlineDocumentText size={20} color="#A1A1A1" />
              <span>Title</span>
              <span className={styles.required}>*</span>
            </div>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter title"
              className={`${styles.input} ${touched.title && errors.title ? styles.errorInput : ''}`}
            />
            {touched.title && errors.title && (
              <span className={styles.errorText}>{errors.title}</span>
            )}
          </div>

          <div className={styles.inputGroup}>
            <div className={styles.label}>
              <BsTextParagraph size={20} color="#A1A1A1" />
              <span>Description</span>
              <span className={styles.required}>*</span>
            </div>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter description"
              className={`${styles.input} ${styles.textarea} ${touched.description && errors.description ? styles.errorInput : ''}`}
              rows={4}
            />
            {touched.description && errors.description && (
              <span className={styles.errorText}>{errors.description}</span>
            )}
          </div>

          <div className={styles.inputGroup}>
            <div className={styles.label}>
              <RiPagesFill size={20} color="#A1A1A1" />
              <span>Page</span>
              <span className={styles.required}>*</span>
            </div>
            <input
              type="text"
              name="page"
              value={formData.page}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter page number"
              className={`${styles.input} ${touched.page && errors.page ? styles.errorInput : ''}`}
            />
            {touched.page && errors.page && (
              <span className={styles.errorText}>{errors.page}</span>
            )}
          </div>

          <div className={styles.actions}>
            <button 
              className={`${styles.button} ${styles.cancelButton}`}
              onClick={onClose}
            >
              Cancel
            </button>
            <button 
              className={`${styles.button} ${styles.confirmButton}`}
              onClick={handleSubmit}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackPopup;