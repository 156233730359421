import axiosInstance from "./axiosInstance";

export const uploadDocumentApi = async (formData) => {
  try {
    const response = await axiosInstance.post("/documents/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    // Transform error to a standardized format
    const errorResponse = {
      success: false,
      code: error.response?.data?.code || 'UPLOAD_ERROR',
      message: error.response?.data?.message || "Failed to upload document",
      ragError: error.response?.data?.ragError
    };
    throw errorResponse;
  }
};
export const deleteDocumentByIdApi = async (documentId) => {
  try {
    const response = await axiosInstance.delete(`/documents/${documentId}`);
    if (!response?.data) {
      throw new Error("Invalid response format");
    }
    return response.data;
  } catch (error) {
    throw (
      error.response?.data || {
        success: false,
        message: "Failed to delete document",
      }
    );
  }
};


export const getDocumentsApi = async () => {
  try {
    const response = await axiosInstance.get("/documents");
    return response.data;
  } catch (error) {
    console.error("Error fetching documents:", error);
    throw (
      error.response?.data || {
        success: false,
        message: "Failed to fetch documents",
      }
    );
  }
};

export const getDocumentByIdApi = async (documentId) => {
  try {
    const response = await axiosInstance.get(`/documents/${documentId}`);
    if (!response?.data) {
      throw new Error("Invalid response format");
    }
    return response.data;
  } catch (error) {
    throw (
      error.response?.data || {
        success: false,
        message: "Failed to fetch document details",
      }
    );
  }
};

export const getDocumentByIdUserApi = async (documentId, userId) => {
  try {
    const response = await axiosInstance.get(`/documents/guestDocument/`, {
      params: {
        did: documentId,
        uid: userId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching document:", error);
    throw (
      error.response?.data || {
        success: false,
        message: "Failed to fetch specific documents",
      }
    );
  }
};

export const toggleDocumentShortlinkApi = async (documentId, enabled) => {
  try {
    const response = await axiosInstance.patch(
      `/documents/toggleShortlink/${documentId}`,
      { enabled }
    );
    return response.data;
  } catch (error) {
    console.error("Error toggling document shortlink:", error);
    throw (
      error.response?.data || {
        success: false,
        message: "Error toggling document shortlink:",
      }
    );
  }
};
