/* eslint-disable react-hooks/exhaustive-deps */
// Built-in/Third-party modules
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import { useMicVAD } from "@ricky0123/vad-react";

// Custom components
import PdfBox from "../../components/PdfBox";
import ChatBox from "../../components/ChatBox";
import Summary from "../../components/Summary";
import OverlayLoader from "../../components/OverlayLoader";
import toaster from "../../components/Toast/Toast";
import ResumeSessionModal from "../../components/ResumeSessionModal/index.jsx";
import TranscriptionDisplay from "../../components/TranscriptionDisplay/index.jsx";

// API methods
import { submitReviewApi, getAnalyzeFeedback } from "../../api/reviewApi.js";
import { generateQuestionsApi } from "../../api/questionApi";
import { generateSummaryApi } from "../../api/summaryApi";

// Contexts
import { useAuth } from "../../context/AuthContext.js";
import { useSession } from "../../context/SessionContext.js";

// Helpers
import {
  float32ToPCM,
  getTranscriptionText,
  getTranscriptionTextWithPage,
  isValidJSON,
} from "../../helper/helper";
import { callApiWithLoader } from "../../helper/helper.jsx";
import {
  connectWhisperWebSocket,
  connectAzureWebSocket,
  handleWhisperMessage,
  handleAzureMessage,
} from "../../helper/websocketHelpers";
import { queueManager } from "../../helper/requestQueueManager.js";

// Static assets
import styles from "./Home.module.css";

const Home = () => {
  // References
  const websocketRef = useRef(null);
  const currentRequestId = useRef(null);
  const initialRestorationRef = useRef(false);
  const { document } = useAuth();
  const {
    sessionData,
    updateSession,
    clearSession,
    showResumePrompt,
    resumeSession,
    discardSession,
    startNewSession,
  } = useSession();
  const pageRef = useRef(1);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("uid");
  const documentId = searchParams.get("did");
  const [isConnected, setIsConnected] = useState(
    sessionData?.isConnected || false
  );
  const [sttService, setSTTService] = useState("azure"); // or 'azure'
  const [metadata, setMetadata] = useState(null);
  const [questions, setQuestions] = useState(sessionData?.questions || []);
  const [summary, setSummary] = useState(sessionData?.summary || []);
  const [step, setStep] = useState(sessionData?.step || 1);
  const stepRef = useRef(step);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(sessionData?.pageNumber || 1);
  const [pdfFile, setPdfFile] = useState(null);
  const [reviewTranscription, setReviewTranscription] = useState(
    sessionData?.transcription || []
  );
  const [transcription, setTranscription] = useState(
    sessionData?.transcription || []
  );
  const [loadinText, setLoadingText] = useState(null);
  const [endLoader, setEndLoader] = useState(false);
  const [micPermission, setMicPermission] = useState(true);
  const [intensity, setIntensity] = useState(0);
  const [transcriptionLoading, setTranscriptionLoading] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    sessionData?.currentQuestionIndex || 0
  );
  const [isReconnecting, setIsReconnecting] = useState(false);

  const generateRequestId = () => Date.now();

  const vad = useMicVAD({
    startOnLoad: false,
    baseAssetPath: "/static/js/",
    onnxWASMBasePath: "/static/js/",
    
    // Increase positive threshold to require more confidence that speech is occurring
    positiveSpeechThreshold: 0.90,  // Default was 0.5
    
    // Increase negative threshold to be more strict about what counts as "not speech"
    negativeSpeechThreshold: 0.45,  // Default was 0.35
    
    // Increase minimum frames required to trigger speech detection
    minSpeechFrames: 4,  // Default was 3, increasing this requires longer speech segments
    
    // Increase redemption frames to require more consistent speech
    redemptionFrames: 12,  // Default was 8
    
    // Add pre-speech padding to capture slightly more context
    preSpeechPadFrames: 2,  // Default was 1
    
    onSpeechStart: () => {
      console.log("User started talking");
      setTranscriptionLoading(true);
    },
    
    onFrameProcessed: ({ isSpeech }) => setIntensity(isSpeech),
    
    onSpeechEnd: (audio) => sendAudio(audio),
    
    onVADMisfire: () => {
      toaster.error("Speech misfire detected (too short)");
      setTranscriptionLoading(false);
    },
    
    // Add additional audio constraints to help with noise reduction
    additionalAudioConstraints: {
      noiseSuppression: true,
      echoCancellation: true,
      autoGainControl: true
    }
  });

  const debouncedUpdateSession = debounce((data) => {
    updateSession(data);
  }, 500);

  useEffect(() => {
    // Only run this effect if we have session data and haven't done initial restoration
    if (sessionData && !initialRestorationRef.current) {
      setStep(sessionData.step);
      setTranscription(sessionData.transcription);
      setQuestions(sessionData.questions);
      setCurrentQuestionIndex(sessionData.currentQuestionIndex);
      setSummary(sessionData.summary);
      setPageNumber(sessionData.pageNumber);
      setIsConnected(sessionData.isConnected);

      if (sessionData.step === 3) {
        setMicPermission(false);
      }

      // If session was connected, reconnect websocket
      if (sessionData?.isConnected && !websocketRef.current) {
        handleConnectSession();
      }

      // Mark initial restoration as complete
      initialRestorationRef.current = true;
    }
  }, [sessionData]);
  // Update session whenever important state changes
  useEffect(() => {
    if (sessionData) {
      debouncedUpdateSession({
        step,
        transcription,
        questions,
        currentQuestionIndex,
        summary,
        pageNumber,
        isConnected,
        sessionStarted: true,
      });
    }
  }, [
    step,
    transcription,
    questions,
    currentQuestionIndex,
    summary,
    pageNumber,
    isConnected,
  ]);
  useEffect(() => {
    if (vad.error) {
      console.error("VAD initialization error:", vad.error);
      // Add user notification
      toaster.error(
        "Microphone initialization failed. Please check your microphone permissions."
      );
      setMicPermission(false);
    }
  }, [vad.error]);
  // Sync the stepRef with the current step
  useEffect(() => {
    stepRef.current = step;
    if (step === 3 || step === 6) {
      setMicPermission(false);
    } else if (step === 5) {
      setMicPermission(true);
    }
  }, [step]);
  useEffect(() => {
    pageRef.current = pageNumber;
  }, [pageNumber]);

  // Effect to manage VAD start/stop based on connection state
  useEffect(() => {
    if (isConnected && micPermission) vad.start();
    else vad.pause();
  }, [isConnected, micPermission, vad]);
  useEffect(() => {
    setTranscriptionLoading(false);
  }, [micPermission]);

  useEffect(() => {
    return () => {
      initialRestorationRef.current = false;
      if (websocketRef.current) {
        websocketRef.current.close();
        websocketRef.current = null;
      }
      currentRequestId.current = null;
      queueManager.clearQueue(); // Clear the queue on unmount
    };
  }, []);

  useEffect(() => {
    if (document?.file_url) {
      setPdfFile(document.file_url);
    }
  }, [document]);

  // Function to handle connecting to the session
  const handleConnectSession = async () => {
    if (
      websocketRef.current &&
      websocketRef.current.readyState === WebSocket.OPEN
    ) {
      toaster.info("WebSocket is already connected.");
      return;
    }
    setEndLoader(true);
    setLoadingText("Connecting...");

    const websocketHandlers = {
      onOpen: () => {
        if (isReconnecting) {
          toaster.success("Reconnected successfully");
          setIsReconnecting(false);
        } else {
          toaster.success("WebSocket connected");
        }
        setIsConnected(true);
        setEndLoader(false);
      },
      onMessage: (event) => {
        if (!isValidJSON(event.data)) {
          console.error("Received invalid JSON");
          return;
        }

        const data = JSON.parse(event.data);
        if (data.type === "pong") {
          console.log("Pong received from server");
          return;
        }

        const currentPage = data.pageNumber;

        if (sttService === "azure") {
          handleAzureMessage(
            event,
            setTranscription,
            setReviewTranscription,
            setEndLoader,
            stepRef.current,
            currentPage,
            setTranscriptionLoading,
            currentRequestId.current,
            setMetadata
          );
        } else {
          handleWhisperMessage(
            event,
            setTranscription,
            setReviewTranscription,
            setEndLoader,
            stepRef.current,
            currentPage,
            setTranscriptionLoading,
            currentRequestId.current
          );
        }
      },
      onError: (event) => {
        console.error("WebSocket error:", event);
        setEndLoader(false);
        if (!isReconnecting) {
          toaster.error("WebSocket error occurred");
        }
      },
      onClose: (event) => {
        console.log("WebSocket closed with code:", event.code);
        setIsConnected(false);
        if (!event.wasClean && event.code === 1006 && !isReconnecting) {
          toaster.info("Connection lost. Attempting to reconnect...");
          setIsReconnecting(true);
        } else if (event.wasClean) {
          handleReset();
        }
      },
    };
    // Connect to appropriate service
    websocketRef.current =
      sttService === "azure"
        ? connectAzureWebSocket(
            websocketHandlers.onOpen,
            websocketHandlers.onMessage,
            websocketHandlers.onError,
            websocketHandlers.onClose
          )
        : connectWhisperWebSocket(
            websocketHandlers.onOpen,
            websocketHandlers.onMessage,
            websocketHandlers.onError,
            websocketHandlers.onClose
          );
  };

  const sendAudio = (audio) => {
    if (!isConnected || !websocketRef.current || !micPermission) {
      toaster.error("WebSocket is not connected.");
      return;
    }

    const requestId = generateRequestId();
    currentRequestId.current = requestId;

    // Create the request function
    const makeRequest = () => {
      return new Promise((resolve, reject) => {
        try {
          const pcmAudioBuffer = float32ToPCM(audio);
          const payload = {
            data: btoa(
              new Uint8Array(pcmAudioBuffer).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            ),
            type: "audio",
            requestId,
            pageNumber: pageRef.current,
            existingTranscription: transcription,
          };

          // Add Azure-specific fields if using Azure
          if (sttService === "azure") {
            payload.language = "auto";
          }

          setTranscriptionLoading(true);

          // Add message handler for this specific request
          const messageHandler = (event) => {
            if (!isValidJSON(event.data)) return;

            const data = JSON.parse(event.data);
            if (data.requestId === requestId) {
              websocketRef.current.removeEventListener(
                "message",
                messageHandler
              );
              resolve(data);
            }
          };

          websocketRef.current.addEventListener("message", messageHandler);
          websocketRef.current.send(JSON.stringify(payload));

          // Add timeout to prevent hanging
          setTimeout(() => {
            websocketRef.current.removeEventListener("message", messageHandler);
            reject(new Error("Request timeout"));
          }, 30000); // 30 second timeout
        } catch (error) {
          reject(error);
        }
      });
    };

    // Enqueue the request
    return queueManager.enqueueRequest(makeRequest).catch((error) => {
      setTranscriptionLoading(false);
      if (error.message === "Request timeout") {
        toaster.error("Transcription request timed out. Please try again.");
      } else {
        toaster.error("Failed to process audio. Please try again.");
      }
      console.error("Audio processing error:", error);
    });
  };

  const disconnectWebSocket = () => {
    if (websocketRef.current) {
      websocketRef.current.close(); // Close WebSocket connection
    }
  };

  const handleStart = async () => {
    if (!pdfFile) {
      toaster.error("Please select pdf file first");
      return;
    }

    // await handleConnectSession();
    startNewSession(); // Initialize the session state
  };

  const handleNext = async () => {
    if (step === 1) {
      await handleTranscriptionStep();
    } else if (step === 2) {
      await handleQuestionsStep();
    } else if (step === 5) {
      await handleReviewStep();
    }
  };

  const handleReviewConfirm = async () => {
    await submitReview(summary);
    setStep(6);
  };

  const handleTranscriptionStep = async () => {
    if (!transcription.length) {
      toaster.warning("No transcription is recorded yet");
      return;
    }

    const transcriptionText = getTranscriptionTextWithPage(transcription, 1);
    await generateQuestions(transcriptionText);
  };

  const generateQuestions = async (transcriptionText) => {
    await callApiWithLoader(
      generateQuestionsApi,
      { transcription: transcriptionText, documentId: documentId },
      handleQuestionsResponse,
      "Generating questions...",
      setLoadingText,
      setEndLoader,
      setMicPermission
    );
  };

  const handleQuestionsResponse = (apiResponse) => {
    if (apiResponse.data.questions) {
      setQuestions(apiResponse.data.questions);
      setStep((prevStep) => prevStep + 1);
    } else {
      toaster.warning("Failed to generate questions.");
    }
  };
  const handleAnalyzeFeedbackResponse = (apiResponse) => {
    if (apiResponse?.analysis?.feedback_summary?.length > 0) {
      setSummary((prevSummary) => ({
        status: prevSummary.status,
        feedback_summary: [
          ...prevSummary.feedback_summary,
          ...apiResponse.analysis.feedback_summary,
        ],
        general_feedback: prevSummary.general_feedback,
        misc: prevSummary.misc,
      }));
      setStep(3);
    }
  };

  const handleReviewStep = async () => {
    if (reviewTranscription.length !== 0) {
      const transcriptionText =
        getTranscriptionTextWithPage(reviewTranscription);
      await callApiWithLoader(
        getAnalyzeFeedback,
        { transcription: transcriptionText, documentId: documentId },
        handleAnalyzeFeedbackResponse,
        "Generating feedback...",
        setLoadingText,
        setEndLoader,
        setMicPermission
      );
    } else {
      setStep(3);
    }
  };

  const handleQuestionsStep = async () => {
    if (currentQuestionIndex < questions.length - 1) {
      incrementQuestionIndex();
      return;
    }

    if (isLastQuestionWithTranscription()) {
      await handleLastQuestion();
    }
  };

  const incrementQuestionIndex = () => {
    setCurrentQuestionIndex((prevState) => prevState + 1);
  };

  const isLastQuestionWithTranscription = () => {
    return (
      transcription.length > 0 && currentQuestionIndex === questions.length - 1
    );
  };

  const handleLastQuestion = async () => {
    const payload = preparePayload();
    await generateAndSubmitSummary(payload);
    setMicPermission(false);
  };

  const preparePayload = () => {
    return {
      transcription: getTranscriptionTextWithPage(transcription, 1),
      questions,
      answers: getTranscriptionText(transcription, 2),
      documentId: documentId ? documentId : null,
    };
  };

  const generateAndSubmitSummary = async (payload) => {
    let retryCount = 0;
    const maxRetries = 3;

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    while (retryCount < maxRetries) {
      try {
        await callApiWithLoader(
          generateSummaryApi,
          payload,
          handleSummaryResponse,
          "Generating summary...",
          setLoadingText,
          setEndLoader,
          setMicPermission
        );
        break;
      } catch (error) {
        const currentRetry = retryCount; // Capture current value
        retryCount++;
        if (retryCount === maxRetries) {
          toaster.error("Failed to generate summary after multiple attempts");
          throw error;
        }
        await delay(1000 * currentRetry); // Use captured value
      }
    }
  };

  const handleSummaryResponse = async (response) => {
    if (response.summary.status !== "success") {
      toaster.error("Unable to generate summary");
      return;
    }

    if (!response.summary.feedback_summary) return;

    toaster.success("Summary generated successfully");
    updateSummaryState(response.summary);
    // Only proceed to next step if there's no document (preview mode)
    if (!documentId || !userId || !document) {
      setStep((prevStep) => prevStep + 1);
      return;
    }

    // If document exists, submit review and wait for response
    setStep((prevStep) => prevStep + 1);
  };

  const updateSummaryState = (summary) => {
    setSummary(summary);
  };

  const submitReview = async (summary) => {
    const reviewData = {
      documentId: parseInt(documentId),
      transcription: getTranscriptionTextWithPage(transcription, 1),
      summary: summary.feedback_summary,
      misc: summary.misc,
      questions: questions,
      answerText: getTranscriptionText(transcription, 2),
    };

    try {
      const response = await submitReviewApi(reviewData);
      handleReviewSubmissionResponse(response);
    } catch (error) {
      handleReviewSubmissionError(error);
    }
  };

  const handleReviewSubmissionResponse = (response) => {
    if (response.success) {
      toaster.success("Review submitted successfully");
      // setStep((prevStep) => prevStep + 1);
    } else {
      toaster.error("Failed to submit review");
    }
  };

  const handleReviewSubmissionError = (error) => {
    console.error("Error submitting review:", error);
    toaster.error("Failed to submit review");
  };

  const handleDownloadPdf = () => {};

  const handleReset = () => {
    // First disconnect and clear refs
    if (websocketRef.current) {
      disconnectWebSocket();
      websocketRef.current = null;
    }
    currentRequestId.current = null;
    queueManager.clearQueue();

    // Then clear session and states
    clearSession();
    setIsConnected(false);
    setMicPermission(true);
    setTranscription([]);
    setReviewTranscription([]);
    setIntensity(0);
    setEndLoader(false);
    setQuestions([]);
    setSummary([]);
    setStep(1);
    setNumPages();
    setPageNumber(1);
    setPdfFile(null);

    // Reset restoration flag
    initialRestorationRef.current = false;
  };

  const handleResumeSession = async () => {
    resumeSession();
    await handleConnectSession();
    toaster.success("Session resumed successfully");
  };

  const handleDiscardSession = () => {
    discardSession();
    clearSession();
    toaster.info("Starting new session");
  };

  return (
    <div className="container-95">
      {endLoader && <OverlayLoader text={loadinText} />}
      <ResumeSessionModal
        isOpen={showResumePrompt}
        onResume={handleResumeSession}
        onDiscard={handleDiscardSession}
        sessionData={sessionData}
      />
      <div className={styles.main}>
        {step < 4 || step === 5 ? (
          <>
            <PdfBox
              pdfFile={pdfFile}
              setPdfFile={setPdfFile}
              numPages={numPages}
              setNumPages={setNumPages}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              isConnected={isConnected}
              isDocument={document?.file_url}
            />
            <ChatBox
              handleStart={handleStart}
              handleNext={handleNext}
              pageNumber={pageNumber}
              step={step}
              setStep={setStep}
              transcription={transcription}
              setTranscription={setTranscription}
              reviewTranscription={reviewTranscription}
              questions={questions}
              isConnected={isConnected}
              isRecording={micPermission}
              setIsRecording={setMicPermission}
              intensity={intensity}
              transcriptionLoading={transcriptionLoading}
              pdfFile={pdfFile}
              currentQuestionIndex={currentQuestionIndex}
              setCurrentQuestionIndex={setCurrentQuestionIndex}
              setSTTService={setSTTService}
              selectedService={sttService}
              handleReviewConfirm={handleReviewConfirm}
              summary={summary}
              setSummary={setSummary}
            />
          </>
        ) : (
          <Summary
            summary={summary}
            handleStartOver={handleReset}
            handleDownloadPdf={handleDownloadPdf}
          />
        )}
      </div>
    </div>
  );
};
export default Home;
